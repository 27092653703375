/* Reset default margin and padding */
* {
  margin: 40;
  padding: 40;
  box-sizing: border-box;
}

/* Apply a blue background color to the body */
body {
  background-color: #007bff;
  font-family: 'Arial', sans-serif;
}

/* Style the root container */
#root {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Add styles for the App component */
.App {
  background-color: #007bff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 40px;
  margin: 40px;
  max-width: 800px;
  width: 100%;
  align-items: center;
}

/* Style headings */
/* Add underline style to the h1 element */
h1 {
  font-size: 3rem;
  align-items: center;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8px;
  text-align: center;
  text-decoration: underline; /* Add underline */
}
/* Style buttons */
button {
  background-color: #4CAF50;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #388E3C;
}

/* Add additional styles as needed */


/* Add styles for the footer */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin-top: 40px;
}

.footer a {
  color: #fff;
  text-decoration: double;
}

.footer a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 19px;
  margin-top: 20px;
}